import React from "react";
import { useTranslation } from "react-i18next";

interface AboutProps {
    isAboutPage: boolean;
}

const About: React.FC<AboutProps> = ({ isAboutPage }) => {
    const { t } = useTranslation();

    const blurAnimation = isAboutPage
        ? 'blurUp 0.5s ease-in-out'
        : 'none';

    return (
        <div className="About" id="page2">
            <div
                className="Blur"
                style={{
                    animation: blurAnimation,
                    opacity: isAboutPage ? 1 : 0,
                }}
            >
                <div className="BlueHead">
                    {t(`About.bluehead`)}
                    <div className="BlueBtm">
                        {t('About.bluebtm')}
                    </div>
                </div>
            </div>
            <div
                className="TabletBlur"
            >
                <div className="BlueHead">
                    {t(`About.bluehead`)}
                    <div className="BlueBtm">
                        {t('About.bluebtm')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
