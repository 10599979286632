import { useEffect, useMemo, useRef, useState } from "react";
import { CategoryArrow } from "../../asset/img";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize";
import { NewsCheckModal } from "../component/index";
import {
  NewsRoomEditService,
  NewsRoomPostService,
} from "../../service/NewsRoomPostService";
import { useLocation, useNavigate } from "react-router-dom";

Quill.register("modules/ImageResize", ImageResize);

const NewsRoomPost = () => {
  const { state: editData } = useLocation();
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [categoryOpenEn, setCategoryOpenEn] = useState(false);
  const [form, setForm] = useState({
    category: "카테고리",
    categoryEn: "Category",
    creatorKR: "",
    creatorEN: "",
    creatorEmail: "",
    titleKR: "",
    titleEN: "",
    image: null,
  });
  const [activeBtn, setActiveBtn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [valueKR, setValueKR] = useState("");
  const [valueEN, setValueEN] = useState("");
  const [isImgUpload, setIsImgUpload] = useState(false);
  const [error, setError] = useState({
    category: "카테고리 설정은 필수 항목입니다.",
    categoryEn: "카테고리 설정은 필수 항목입니다.",
    creatorKR: "작성자 (한국어) 입력란은 필수 입력란입니다.",
    creatorEN: "작성자 (영어) 입력란은 필수 입력란입니다.",
    creatorEmail: "이메일 입력란은 필수 입력란입니다.",
    titleKR: "제목 (한국어) 입력란은 필수 입력란입니다.",
    titleEN: "제목 (영어) 입력란은 필수 입력란입니다.",
    contentKR: "상세내용 (한국어) 입력란은 필수 입력란입니다.",
    contentEN: "상세내용 (영어) 입력란은 필수 입력란입니다.",
  });

  const emailRegEx =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const navigate = useNavigate();

  // console.log(editData);

  useEffect(() => {
    const isLogin = localStorage.getItem("userName");
    if (!isLogin) {
      navigate("/FourchainsManagerLoginPage");
    }
  }, []);

  useEffect(() => {
    if (editData) {
      setForm({
        category: editData.category,
        categoryEn: editData.categoryEn,
        titleKR: editData.title,
        titleEN: editData.titleEn,
        creatorKR: editData.writer,
        creatorEN: editData.writerEn,
        creatorEmail: editData.writerEmail,
      });
      setValueKR(editData.contents);
      setValueEN(editData.contentsEn);
      if (editData.imageUrl) {
        setIsImgUpload(true);
      }
      setError({
        category: "",
        categoryEn: "",
        creatorKR: "",
        creatorEN: "",
        creatorEmail: "",
        titleKR: "",
        titleEN: "",
        contentKR: "",
        contentEN: "",
      });
    }
  }, [editData]);

  const onClickCategoryBtn = () => {
    setCategoryOpen(!categoryOpen);
  };

  const onClickCategoryBtnEn = () => {
    setCategoryOpenEn(!categoryOpenEn);
  };

  const onClickCategory = (category) => {
    setForm({ ...form, category: category });

    if (category !== "카테고리") setError({ ...error, category: "" });
    else setError({ ...error, category: "카테고리 설정은 필수 항목입니다." });

    setCategoryOpen(false);
    setCategoryOpenEn(false);
  };

  const onClickCategoryEn = (categoryEn) => {
    setForm({ ...form, categoryEn: categoryEn });

    if (categoryEn !== "Category") setError({ ...error, categoryEn: "" });
    else setError({ ...error, categoryEn: "카테고리 설정은 필수 항목입니다." });

    setCategoryOpenEn(false);
  };

  let today = new Date();

  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let date = today.getDate();

  let todayDate = `${year}/${month}/${date}`;

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, false] }],
          ["bold", "blockquote"],

          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [{ align: [] }, { color: [] }, { background: [] }],
          ["clean"],
        ],
      },
      ImageResize: {
        modules: ["Resize", "DisplaySize"],
      },
    };
  }, []);

  const formats = [
    "header",
    "bold",
    "list",
    "bullet",
    "blockquote",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];

  const formData = new FormData();

  const removeTag = (html) => {
    const content = new DOMParser().parseFromString(html, "text/html");
    return content.body.textContent || "";
  };

  const onChangeValueKR = (val) => {
    setValueKR(val);
    if (removeTag(val).length > 0) setError({ ...error, contentKR: "" });
    else
      setError({
        ...error,
        contentKR: "상세내용 (한국어) 입력란은 필수 입력란입니다.",
      });
  };

  const onChangeValueEN = (val) => {
    setValueEN(val);
    if (removeTag(val).length > 0) setError({ ...error, contentEN: "" });
    else
      setError({
        ...error,
        contentEN: "상세내용 (영어) 입력란은 필수 입력란입니다.",
      });
  };

  const onChangeTitleKR = (e) => {
    setForm({ ...form, titleKR: e.target.value });
    if (e.target.value.length > 0) setError({ ...error, titleKR: "" });
    else
      setError({
        ...error,
        titleKR: "제목 (한국어) 입력란은 필수 입력란입니다.",
      });
  };

  const onChangeTitleEN = (e) => {
    setForm({ ...form, titleEN: e.target.value });
    if (e.target.value.length > 0) setError({ ...error, titleEN: "" });
    else
      setError({
        ...error,
        titleEN: "제목 (영어) 입력란은 필수 입력란입니다.",
      });
  };

  const onChangeCreatorKR = (e) => {
    setForm({ ...form, creatorKR: e.target.value });
    if (e.target.value.length > 0) setError({ ...error, creatorKR: "" });
    else
      setError({ ...error, creatorKR: "작성자 입력란은 필수 입력란입니다." });
  };

  const onChangeCreatorEN = (e) => {
    setForm({ ...form, creatorEN: e.target.value });
    if (e.target.value.length > 0) setError({ ...error, creatorEN: "" });
    else
      setError({ ...error, creatorEN: "작성자 입력란은 필수 입력란입니다." });
  };

  const onChangeCreatorEmail = (e) => {
    setForm({ ...form, creatorEmail: e.target.value });
    if (e.target.value.length > 0) {
      setError({ ...error, creatorEmail: "" });
      if (emailRegEx.test(e.target.value))
        setError({ ...error, creatorEmail: "" });
      else
        setError({
          ...error,
          creatorEmail: "이메일 형식이 올바르지 않습니다.",
        });
    } else
      setError({
        ...error,
        creatorEmail: "이메일 입력란은 필수 입력란입니다.",
      });
  };

  const onChangeImage = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageData = { image: file, imageName: file.name };
      setForm({ ...form, image: imageData });
      setIsImgUpload(true);
    } else {
      if (editData && editData?.imageUrl) {
        setIsImgUpload(true);
      } else setIsImgUpload(false);
    }
  };

  const quillRef = useRef();

  const onClickModal = () => {
    if (activeBtn) {
      setModalOpen(true);
      document.body.classList.add("modal-open");
    } else return;
  };

  const handleSubmit = async (data, formData) => {
    try {
      if (editData) {
        data = { ...data, id: editData.id };
        await NewsRoomEditService(data, formData);
      } else {
        await NewsRoomPostService(data, formData);
      }
      setForm({
        category: "카테고리",
        categoryEn: "Category",
        creatorKR: "",
        creatorEN: "",
        creatorEmail: "",
        titleKR: "",
        titleEN: "",
        image: null,
      });
      setValueKR("");
      setValueEN("");
      setIsImgUpload(false);
      inputRef.current.value = "";
      alert("글 작성이 완료됐습니다!");
      navigate("/FourchainsManagerFirstPage");
    } catch (error) {
      alert("글 작성에 실패했습니다. 다시 시도해 주세요.");
      console.error("실패", error);
    }
  };

  const onConfirm = () => {
    const data = {
      category: form.category,
      categoryEn: form.categoryEn,
      date: todayDate,
      writer: form.creatorKR,
      writerEmail: form.creatorEmail,
      writerEn: form.creatorEN,
    };

    formData.append("title", form.titleKR);
    formData.append("titleEn", form.titleEN);
    formData.append("contents", valueKR);
    formData.append("contentsEn", valueEN);
    if (form.image) {
      formData.append("image", form.image.image);
      // formData.append("imageName", form.image.imageName);
    }
    handleSubmit(data, formData);
    setModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const onCancel = () => {
    setModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const INPUT_LIST = [
    { title: "작성날짜", value: todayDate },
    {
      title: "작성자 (한국어)",
      value: form.creatorKR,
      handler: onChangeCreatorKR,
    },
    {
      title: "작성자 이메일",
      value: form.creatorEmail,
      handler: onChangeCreatorEmail,
    },
  ];
  const INPUT_LISTEN = [
    {
      title: "작성자 (영어)",
      value: form.creatorEN,
      handler: onChangeCreatorEN,
    },
  ];

  useEffect(() => {
    // 글 수정 시 error 메시지 뜨는 거 방지
    if (
      form.category !== "카테고리" &&
      form.categoryEn !== "Category" &&
      removeTag(valueKR).length > 0 &&
      removeTag(valueEN).length > 0 &&
      form.titleKR.length > 0 &&
      form.titleEN.length > 0 &&
      form.creatorKR.length > 0 &&
      form.creatorEN.length > 0 &&
      form.creatorEmail.length > 0 &&
      emailRegEx.test(form.creatorEmail)
    ) {
      setError({
        category: "",
        categoryEn: "",
        creatorKR: "",
        creatorEN: "",
        creatorEmail: "",
        titleKR: "",
        titleEN: "",
        contentKR: "",
        contentEN: "",
      });
    }

    const isAllValid = Object.values(error).every((val) => val.length === 0);
    if (isAllValid && isImgUpload) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  }, [form, isImgUpload, valueKR, valueEN]);

  const convertImgFileName = (fileName) => {
    return fileName.split("uploads/")[1];
  };

  const inputRef = useRef();

  return (
    <div className="NewsRoomPost">
      <div className="Blur">
        <div className="MainContainer">
          <div className="NewsRoomTitle">
            {editData ? "뉴스룸 수정" : "뉴스룸 작성"}
          </div>
          <div className="Container">
            <div className="LineContainer">
              <div className="InputContainer">
                <span className="InputTitle">카테고리 (한국어)</span>
                <div onClick={onClickCategoryBtn} className="Category">
                  <span>
                    {form.category}{" "}
                    <img src={CategoryArrow} onClick={onClickCategoryBtn} />
                  </span>
                  {categoryOpen && (
                    <div className="Dropdown">
                      <span onClick={() => onClickCategory("뉴스")}>뉴스</span>
                      <span onClick={() => onClickCategory("소식")}>소식</span>
                    </div>
                  )}
                </div>
                {error.category && (
                  <span className="ErrorMessage">{error.category}</span>
                )}
              </div>
              {INPUT_LIST.map((list) => {
                return (
                  <div key={list.title} className="InputContainer">
                    <span className="InputTitle">{list.title}</span>
                    <input
                      value={list.value}
                      onChange={list.handler && list.handler}
                      className="Input"
                      placeholder="필수"
                      disabled={list.title === "작성날짜"}
                    />
                    {list.title === "작성자 (한국어)"
                      ? error.creatorKR && (
                          <span className="ErrorMessage">
                            {error.creatorKR}
                          </span>
                        )
                      : list.title === "작성자 (영어)"
                      ? error.creatorEN && (
                          <span className="ErrorMessage">
                            {error.creatorEN}
                          </span>
                        )
                      : list.title === "작성자 이메일"
                      ? error.creatorEmail && (
                          <span className="ErrorMessage">
                            {error.creatorEmail}
                          </span>
                        )
                      : null}
                  </div>
                );
              })}
            </div>
            <div className="LineContainer">
              <div className="InputContainer">
                <span className="PostTitle InputTitle">제목 (한국어)</span>
                <input
                  className="PostTitleInput Input"
                  value={form.titleKR}
                  onChange={onChangeTitleKR}
                  placeholder="필수"
                />
              </div>
            </div>
            {error.titleKR && (
              <span className="ErrorMessage">{error.titleKR}</span>
            )}

            <div>
              <div className="InputContainer">
                <span className="PostTitle InputTitle">파일 첨부</span>
                {editData &&
                  `현재 이미지 파일명: ${convertImgFileName(
                    editData.imageUrl
                  )}`}{" "}
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={onChangeImage}
            />
            {editData && (
              <div className="ErrorMessage" style={{ marginTop: 5 }}>
                * 이미지를 변경하고 싶으시다면 파일 선택을 눌러 새 이미지를
                선택해 주세요.
              </div>
            )}
            {!isImgUpload && (
              <div className="ErrorMessage">
                이미지 파일 업로드는 필수 항목입니다.
              </div>
            )}
            <div className="LineContainer">
              <div className="InputContainer">
                <span className="ContentTitle InputTitle">
                  상세내용 (한국어)
                </span>
                <ReactQuill
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px",
                  }}
                  modules={modules}
                  formats={formats}
                  value={valueKR}
                  onChange={(e) => {
                    onChangeValueKR(e);
                  }}
                  ref={quillRef}
                />
              </div>
            </div>
            {error.contentKR && (
              <span className="ErrorMessage">{error.contentKR}</span>
            )}
            <div className="LineContainer">
              <div className="InputContainer">
                <div className="LineContainer">
                  <div className="InputContainer">
                    <span className="InputTitle">카테고리 (영어)</span>
                    <div onClick={onClickCategoryBtnEn} className="Category">
                      <span>
                        {form.categoryEn}{" "}
                        <img
                          src={CategoryArrow}
                          onClick={onClickCategoryBtnEn}
                        />
                      </span>
                      {categoryOpenEn && (
                        <div className="Dropdown">
                          <span onClick={() => onClickCategoryEn("News")}>
                            News
                          </span>
                          <span onClick={() => onClickCategoryEn("Updates")}>
                            Updates
                          </span>
                        </div>
                      )}
                    </div>
                    {error.category && (
                      <span className="ErrorMessage">{error.category}</span>
                    )}
                  </div>
                  {INPUT_LISTEN.map((list) => {
                    return (
                      <div key={list.title} className="InputContainer">
                        <span className="InputTitle">{list.title}</span>
                        <input
                          value={list.value}
                          onChange={list.handler && list.handler}
                          className="Input"
                          placeholder="필수"
                          disabled={list.title === "작성날짜"}
                        />
                        {list.title === "작성자 (영어)"
                          ? error.creatorEN && (
                              <span className="ErrorMessage">
                                {error.creatorEN}
                              </span>
                            )
                          : null}
                      </div>
                    );
                  })}
                </div>
                <div className="LineContainer">
                  <div className="InputContainer">
                    <span className="PostTitle InputTitle">제목 (영어)</span>
                    <input
                      className="PostTitleInput Input"
                      value={form.titleEN}
                      onChange={onChangeTitleEN}
                      placeholder="필수"
                    />
                  </div>
                </div>
                {error.titleEN && (
                  <span className="ErrorMessage">{error.titleEN}</span>
                )}
                <span className="ContentTitle InputTitle">상세내용 (영어)</span>
                <ReactQuill
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px",
                  }}
                  modules={modules}
                  formats={formats}
                  value={valueEN}
                  onChange={(e) => {
                    onChangeValueEN(e);
                  }}
                  ref={quillRef}
                />
              </div>
            </div>
            {error.contentEN && (
              <span className="ErrorMessage">{error.contentEN}</span>
            )}
            <div className="BtnContainer">
              <div className={activeBtn ? "active" : ""} onClick={onClickModal}>
                작성 완료
              </div>
            </div>
            {modalOpen && (
              <NewsCheckModal
                form={form}
                valueKR={valueKR}
                valueEN={valueEN}
                date={todayDate}
                onConfirm={onConfirm}
                onCancel={onCancel}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsRoomPost;
