import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../locales/i18n";

const Mobile2022 = () => {
    const { t } = useTranslation();
    const his = `History.events.2022`;

    // 현재 언어 확인
    const isEN = i18n.language === 'en';

    return (
        <div className="Mobile2023">
            <span className="MobileYears">
                2022
            </span>

            <div className="TextBox">
                <span className="BoldText"
                    style={{ fontSize: isEN ? '20px' : undefined }}>
                    {t(`${his}.eventbold.event1`)}<br/>
                </span>
                <span className="Texts"
                    style={{ fontSize: isEN ? '18px' : undefined }}>
                    {t(`${his}.eventhis.event1`)}
                </span>
            </div>
        </div>
    )
}
export default Mobile2022;