import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface HomeServiceProps {
    isfirstServicePage: boolean;
}

const HomeService: React.FC<HomeServiceProps> = ({ isfirstServicePage }) => {
    const { t } = useTranslation();

    const blurAnimation = isfirstServicePage
        ? 'MainSlogan 0.7s ease-in-out'
        : 'none';

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div className={`HomeService ${loaded ? "loaded" : ""}`}>
            <div className="TextHome"
                style={{
                    animation: blurAnimation,
                    opacity: isfirstServicePage ? 1 : 0,
                }}
            >
                <span className="TabName">
                    Service
                </span>
                <div className="FCulHead">
                    {t(`HomeService.fculhead`)}
                </div>
                <div className="FCulBtm">
                    {t(`HomeService.fculbtm`)}
                </div>
            </div>
        </div>
    )
}
export default HomeService;