import React from "react";
import { SustainImg } from "../../asset/img/index";
import { useTranslation } from "react-i18next";

interface SustainProps {
    isSustainPage: boolean;
}

const Sustainable: React.FC<SustainProps> = ({ isSustainPage }) => {
    const { t } = useTranslation();

    const blurAnimation = isSustainPage
        ? 'blur 0.5s ease-in-out'
        : 'none';

    return (
        <div className="Sustainable">
            <div className="SusImgBox">
                <img src={SustainImg} />
            </div>
            <div className="SusText">
                <div className="MainText"
                    >
                    {t(`Sustainable.maintext`)}
                </div>
                <div className="SubText">
                    {t(`Sustainable.subtext1`)}<br /><br />
                    {t(`Sustainable.subtext2`)}<br /><br />
                    {t(`Sustainable.subtext3`)}<br /><br />
                    {t(`Sustainable.subtext4`)}
                </div >
                <div className="Orner">
                    {t(`Sustainable.orner`)}
                </div>
            </div>
        </div>
    )
}

export default Sustainable;