import React, { useEffect } from 'react';
import { ManageBack, DevelopManage, DevelopManageEN } from "../../asset/img/index";
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';


interface DevelopProps {
  setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Manage: React.FC<DevelopProps> = ({ setCurrentSection }) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const procedureImage = currentLanguage === "ko" ? DevelopManage : DevelopManageEN;

  useEffect(() => {
    setCurrentSection("history");
    return () => {
    };
  }, [setCurrentSection]);

  return (
    <div className='FourchainsClass'>
      <div className='Fourchains'>
        <span className='ShortName'>
          {t(`DetailTeam.shortname`)}
        </span>
        <span className='CenterName'>
          {t(`DetailTeam.Manage.centername`)}
        </span>
        <img src={ManageBack} alt="ManageBack" />
        <span className='DevelopName'>
          {t(`DetailTeam.Manage.developname`)}
        </span>


        <div className='Develop'>
          <span className='Bold weightBold'>
            {t(`DetailTeam.Manage.weightbold1`)}
          </span>
          <span className='Bold'>
            {t(`DetailTeam.Manage.bold1`)}
          </span>
          <span className='Light'>
            {t(`DetailTeam.Manage.light1`)}
          </span>

          <span className='Bold weightBold'>
            {t(`DetailTeam.Manage.weightbold2`)}
          </span>
          <span className='Bold'>
            {t(`DetailTeam.Manage.bold2`)}
          </span>
          <span className='Light'>
            {t(`DetailTeam.Manage.light2`)}
          </span>

          <span className='Bold weightBold'>
            {t(`DetailTeam.Manage.weightbold3`)}
          </span>
          <span className='Bold'>
            {t(`DetailTeam.Manage.bold3`)}
          </span>
          <span className='Light'>
            {t(`DetailTeam.Manage.light3`)}
          </span>

          <span className='Bold weightBold'>
            {t(`DetailTeam.Manage.weightbold4`)}
          </span>
          <span className='Bold'>
            {t(`DetailTeam.Manage.bold4`)}
          </span>
          <span className='Light'>
            {t(`DetailTeam.Manage.light4`)}
          </span>

          <span className='Bold weightBold'>
            {t(`DetailTeam.Manage.weightbold5`)}
          </span>
          <span className='Bold'>
            {t(`DetailTeam.Manage.bold5`)}
          </span>
          <span className='Light'>
            {t(`DetailTeam.Manage.light5`)}
          </span>

          <div className='DevelopImg'>
            <img src={procedureImage} alt="DevelopManage" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Manage; 