import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

const Ver2023 = () => {
    const { t } = useTranslation();
    const his = `History.events.2023`;

    // 현재 언어 확인
    const isEN = i18n.language === 'en';

    return (
        <div className="History2019">
            <div className="Years2019">
                2023
            </div>
            <div className="BoldHis"
                style={{ fontSize: isEN ? '16px' : undefined }}>
                {t(`${his}.eventbold.event1`)}<br />
                {t(`${his}.eventbold.event2`)}<br />
                {t(`${his}.eventbold.event3`)}<br />
                {t(`${his}.eventbold.event4`)}<br />
                {t(`${his}.eventbold.event5`)}<br />
                {t(`${his}.eventbold.event6`)}<br />
                {t(`${his}.eventbold.event7`)}
            </div>
            <div className="His2019"
                style={{ fontSize: isEN ? '14px' : undefined }}>
                {t(`${his}.eventhis.event1`)}<br />
                {t(`${his}.eventhis.event2`)}<br />
                {t(`${his}.eventhis.event3`)}<br />
                {t(`${his}.eventhis.event4`)}<br />
                {t(`${his}.eventhis.event5`)}<br />
                {t(`${his}.eventhis.event6`)}<br />
            </div>
        </div>
    )
}

export default Ver2023;