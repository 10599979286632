import React, { useEffect } from 'react';
import {
    Developer,
    DevelopDev,
    DevelopDevEN
} from "../../asset/img/index";
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';

interface DevelopProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Develop: React.FC<DevelopProps> = ({ setCurrentSection }) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const procedureImage = currentLanguage === "ko" ? DevelopDev : DevelopDevEN;

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'>
                <span className='ShortName'>
                    {t(`DetailTeam.shortname`)}
                </span>
                <span className='CenterName'>
                    {t(`DetailTeam.Develop.centername`)}
                </span>
                <img src={Developer} alt="Developer" />
                <span className='DevelopName'>
                    {t(`DetailTeam.Develop.developname`)}
                </span>

                <div className='Develop'>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Develop.weightbold1`)}
                    </span>
                    <span className='Bold'>
                        #React #Typescript #redux #Node.js # Express.js #Docker ..
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Develop.light1`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Develop.weightbold2`)}
                    </span>
                    <span className='Bold'>
                        #UI/UX #My-SQL #Figma #Github #Notion
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Develop.light2`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Develop.weightbold3`)}
                    </span>
                    <span className='Bold'>
                        #PlatformStability #ServerClientlntegration
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Develop.light3`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Develop.weightbold4`)}
                    </span>
                    <span className='Bold'>
                        #MachineLearning #DeepLearning
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Develop.light4`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Develop.weightbold5`)}
                    </span>
                    <span className='Bold'>
                        {t(`DetailTeam.Develop.bold5`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Develop.light5`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Develop.weightbold6`)}
                    </span>
                    <span className='Bold'>
                        {t(`DetailTeam.Develop.bold6`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Develop.light6`)}
                    </span>

                    <div className='DevelopImg'>
                        <img src={procedureImage} alt="DevelopDev" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Develop; 