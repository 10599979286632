import React, { useEffect, useRef, useState } from "react";
import {
    TeamBackPlus,
    TeamBackDelete,
    MobileDelete,
    MobilePlus
} from "../../asset/img/index";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

const TeamCulture: React.FC = () => {
    const card1Ref = useRef<HTMLDivElement>(null);
    const card2Ref = useRef<HTMLDivElement>(null);
    const card3Ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    // 현재 언어 확인
    const isEN = i18n.language === 'en';
    const [isMobile, setIsMobile] = useState(false);
    const [tablet, setTablet] = useState(false);

    const handleResize = () => {
        const width = window.innerWidth;
        setIsMobile(width <= 320);
        setTablet(width > 320 && width <= 756);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const addClickListeners = (cardElement: HTMLDivElement | null, flipper: () => void) => {
        if (cardElement) {
            cardElement.addEventListener("click", flipper);
        }
    };

    useEffect(() => {
        const card1Element = card1Ref.current;
        const card2Element = card2Ref.current;
        const card3Element = card3Ref.current;

        const flipper1 = () => {
            if (card1Element) {
                card1Element.style.transform = "rotateY(180deg)";
                addClickListeners(card1Element, innerFlipper1);
            }
        };

        const innerFlipper1 = () => {
            if (card1Element) {
                card1Element.style.transform = "rotateY(0deg)";
                addClickListeners(card1Element, flipper1);
                card1Element.removeEventListener("click", innerFlipper1);
            }
        };

        addClickListeners(card1Element, flipper1);

        const flipper2 = () => {
            if (card2Element) {
                card2Element.style.transform = "rotateY(180deg)";
                addClickListeners(card2Element, innerFlipper2);
            }
        };

        const innerFlipper2 = () => {
            if (card2Element) {
                card2Element.style.transform = "rotateY(0deg)";
                addClickListeners(card2Element, flipper2);
                card2Element.removeEventListener("click", innerFlipper2);
            }
        };

        addClickListeners(card2Element, flipper2);

        const flipper3 = () => {
            if (card3Element) {
                card3Element.style.transform = "rotateY(180deg)";
                addClickListeners(card3Element, innerFlipper3);
            }
        };

        const innerFlipper3 = () => {
            if (card3Element) {
                card3Element.style.transform = "rotateY(0deg)";
                addClickListeners(card3Element, flipper3);
                card3Element.removeEventListener("click", innerFlipper3);
            }
        };

        addClickListeners(card3Element, flipper3);

        return () => {
            if (card1Element) {
                card1Element.removeEventListener("click", flipper1);
                card1Element.removeEventListener("click", innerFlipper1);
            }
            if (card2Element) {
                card2Element.removeEventListener("click", flipper2);
                card2Element.removeEventListener("click", innerFlipper2);
            }
            if (card3Element) {
                card3Element.removeEventListener("click", flipper3);
                card3Element.removeEventListener("click", innerFlipper3);
            }
        };
    }, []);

    // css변경사항 => 데스크탑(영어버전)
    // FontSpan 수정부분
    const styleFont: React.CSSProperties = {
        fontSize: isEN ? '22px' : undefined,
        textAlign: isEN ? 'center' : 'left'
    };
    // BackDiv 수정부분
    const stylePadding = {
        padding: isEN ? '0 35px' : undefined,
        paddingTop: isEN ? '55px' : undefined
    };
    // BackSpan 수정부분 
    const styleSpan = {
        fontSize: isEN ? '14px' : undefined,
        lineHeight: isEN ? '170%' : undefined
    };
    //

    // css변경사항 => 테블릿(영어버전)
    // BackDiv 수정부분
    const styleBackDiv = {
        padding: isEN ? '35px 25px 0' : undefined
    }
    // BackSpan 수정부분
    const styleBackSpan = {
        fontSize: isEN ? '13.5px' : undefined
    }

    // css변경사항 => 모바일(영어버전)
    // BackDiv 수정부분
    const styleBackDivM = {
        padding: isEN ? '25px 25px 0' : undefined
    }
    // BackSpan 수정부분
    const styleBackSpanM = {
        fontSize: isEN ? '11px' : undefined,
        lineHeight: isEN ? '160%' : undefined
    }


    return (
        <div className="TeamCulture">
            <div className="TitleBox">
                <span className="TeamTitle">
                    {t(`TemCulture.teamtitle`)}
                </span>
                <span className="ActiveTitle">
                    {t(`TemCulture.activetitle`)}
                </span>
            </div>
            <div className="Top">
                <div ref={card1Ref} className="card">
                    <div className="FrontOne">
                        <span style={styleFont}>
                            {t(`TemCulture.frontone`)}
                        </span>
                        <img className="FrontPlus" src={TeamBackPlus} alt="Team Back Plus" />
                        <img className="MobileFrontPlus" src={MobilePlus} alt="Team Back Plus" />
                    </div>
                    <div className="BackOne"
                        style={isMobile ? styleBackDivM : (tablet ? styleBackDiv : stylePadding)}>
                        <span style={tablet ? styleBackSpanM  : styleBackSpan }>
                            {t(`TemCulture.backone`)}
                        </span>
                        <img className="FrontDelete" src={TeamBackDelete} alt="Team Back Plus" />
                        <img className="MobileFrontDelete" src={MobileDelete} alt="Team Back Plus" />
                    </div>
                </div>
                <div ref={card2Ref} className="card">
                    <div className="FrontTwo">
                        <span style={styleFont}>
                            {t(`TemCulture.fronttwo`)}
                        </span>
                        <img className="FrontPlus" src={TeamBackPlus} alt="Team Back Plus" />
                        <img className="MobileFrontPlus" src={MobilePlus} alt="Team Back Plus" />
                    </div>
                    <div className="BackTwo"
                        style={isMobile ? styleBackDivM : (tablet ? styleBackDiv : stylePadding)}>
                        <span style={tablet ? styleBackSpanM : styleBackSpan}>
                            {t(`TemCulture.backtwo`)}
                        </span>
                        <img className="FrontDelete" src={TeamBackDelete} alt="Team Back Plus" />
                        <img className="MobileFrontDelete" src={MobileDelete} alt="Team Back Plus" />
                    </div>
                </div>
                <div ref={card3Ref} className="card">
                    <div className="FrontThree">
                        <span style={styleFont}>
                            {t(`TemCulture.frontthree`)}
                        </span>
                        <img className="FrontPlus" src={TeamBackPlus} alt="Team Back Plus" />
                        <img className="MobileFrontPlus" src={MobilePlus} alt="Team Back Plus" />
                    </div>
                    <div className="BackThree"
                        style={isMobile ? styleBackDivM : (tablet ? styleBackDiv : stylePadding)}>
                        <span style={tablet ? styleBackSpanM : (tablet ? styleBackSpan : styleSpan)}>
                            {t(`TemCulture.backthree`)}
                        </span>
                        <img className="FrontDelete" src={TeamBackDelete} alt="Team Back Plus" />
                        <img className="MobileFrontDelete" src={MobileDelete} alt="Team Back Plus" />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TeamCulture;
