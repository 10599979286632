import { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";

const MainSlogan = () => {
  const { t } = useTranslation();

  const MAIN_SLOGAN_LIST = [
    {
      title: t(`Slogan.slogan1.title`),
      secondTitle: t(`Slogan.slogan1.secondTitle`),
      content:
        t(`Slogan.slogan1.content`),
      slideNum: 0,
    },
    {
      title: t(`Slogan.slogan2.title`),
      secondTitle: t(`Slogan.slogan2.secondTitle`),
      content: t(`Slogan.slogan2.content`),
      slideNum: 1,
    },
    {
      title: t(`Slogan.slogan3.title`),
      secondTitle: t(`Slogan.slogan3.secondTitle`),
      content: t(`Slogan.slogan3.content`),
      slideNum: 2,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [fade, setFade] = useState(false);
  const onClickSlideBtn = (slideNum: number) => {
    setCurrentSlide(slideNum);
  };

  const blurAnimation = fade ? "MainSlogan 3s ease-in-out" : "none";

  useEffect(() => {
    setFade(false);
    const fadeTimer = setTimeout(() => setFade(true), 100);
    const slideTimer = setInterval(() => {
      if (currentSlide === 2) setCurrentSlide(0);
      else setCurrentSlide((currentSlide) => currentSlide + 1);
    }, 7000);
    return () => {
      clearTimeout(fadeTimer);
      clearInterval(slideTimer);
    };
  }, [currentSlide]);

  
  return (
    <div className="MainSlogan">
      <div className="MainContainer">
        {MAIN_SLOGAN_LIST.map((slogan) => {
          return (
            slogan.slideNum === currentSlide && (
              <div
                key={slogan.slideNum}
                className="MainSloganTextWrapper"
                style={{
                  animation: blurAnimation,
                  opacity: fade ? 1 : 0,
                }}
              >
                <div className="MainSloganTitle">{slogan.title}</div>
                <div className="MainSloganTitleSecond">
                  {slogan.secondTitle}
                </div>
                <div className="MainSloganContent">{slogan.content}</div>
              </div>
            )
          );
        })}
        <div className="SlideBtnWrapper">
          {MAIN_SLOGAN_LIST.map((slogan) => (
            <div
              key={slogan.slideNum}
              onClick={() => onClickSlideBtn(slogan.slideNum)}
              className={
                slogan.slideNum === currentSlide
                  ? "ActiveSlideBtn SlideBtn"
                  : "SlideBtn"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainSlogan;
