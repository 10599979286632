import React, { useState, useEffect } from "react";
import { SearchIcon } from "../../asset/img";
import { Link, useNavigate } from "react-router-dom";

const ManagerPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isLogin = localStorage.getItem("userName");
    if (!isLogin) {
      navigate("/FourchainsManagerLoginPage");
    }
  }, []);

  return (
    <div className="ManagerPage">
      <div className="Blur">
        <div className="MainContainer">
          <div className="HiHello">
            포체인스 관리자 페이지입니다.
            <br />
            오늘도 즐거운 하루 되세요.
          </div>
          <div className="SearchBox">
            <input
              className="SearchText"
              type="text"
              placeholder="무엇을 도와드릴까요?"
            />
            <img src={SearchIcon} alt="Search Icon" />
          </div>
          <div className="SelectMenu">
            <div className="FirstLine">
              <Link to="/FourchainsManagerNewsRoomPage" className="AnyBox">
                <div>뉴스룸 작성</div>
              </Link>
              <Link to="/ManagerNewsEditDeletePage" className="AnyBox">
                <div>작성글 수정 / 삭제</div>
              </Link>
              <Link to="/" className="AnyBox">
                <div>배경화면 수정</div>
              </Link>
            </div>
            <div className="SecondLine">
              <Link to="/" className="AnyBox">
                <div>관리자 아이디 생성</div>
              </Link>
              <Link to="/" className="AnyBox">
                <div>건의사항</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerPage;
