import React, { useEffect, useState } from "react";
import { SolutionProcedureEN, SolutionProcedureImg, SolutionProceduremobile, SolutionProceduremobileEN } from "../../asset/img/index";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

const SolutionProcedure = () => {
    const { t } = useTranslation();
    //현재 언어정보
    const currentLanguage = i18n.language;
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 755);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const procedureImage = currentLanguage === 'ko'
        ? (isMobile ? SolutionProceduremobile : SolutionProcedureImg)
        : (isMobile ? SolutionProceduremobileEN : SolutionProcedureEN);

    return (
        <div className="Procedure">
            <span className="Explanation">
                {t(`Solution.explan1`)}
            </span>
            <span className="BigText">
                {t(`SolutionProcedure.bigtext`)}
            </span>
            <img className="SolutionImg" src={procedureImage} />
        </div>
    )
}
export default SolutionProcedure;