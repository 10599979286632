import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface FirstCultureProps {
    isfirstculturePage: boolean;
}

const FirstCulture: React.FC<FirstCultureProps> = ({ isfirstculturePage }) => {

    const blurAnimation = isfirstculturePage
        ? 'MainSlogan 0.7s ease-in-out'
        : 'none';

    const [loaded, setLoaded] = useState(false);
    const { t } = useTranslation();     

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div className={`FirstCulture ${loaded ? "loaded" : ""}`}>
            <div className="TextCulture"
                style={{
                    animation: blurAnimation,
                    opacity: isfirstculturePage ? 1 : 0,
                }}
            >
                <span className="TabName">
                    Team Culture
                </span>
                <div className="FCulHead">
                    {t(`FirstCulture.fculhead`)}
                </div>
                <div className="FCulBtm">
                    {t(`FirstCulture.fculbtm`)}
                </div>
            </div>
        </div>
    );
};

export default FirstCulture;