import React, { useEffect } from 'react';
import { DetailRDImg, DevelopRandD, DevelopRandDEN } from "../../asset/img/index";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';

interface RandDProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const RandD: React.FC<RandDProps> = ({ setCurrentSection }) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const procedureImage = currentLanguage === "ko" ? DevelopRandD : DevelopRandDEN;

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'>
                <span className='ShortName'>
                    {t(`DetailTeam.shortname`)}
                </span>
                <span className='CenterName'>
                    {t(`DetailTeam.RandD.centername`)}
                </span>
                <img src={DetailRDImg} alt="DetailRDImg" />
                <span className='DevelopName'>
                    {t(`DetailTeam.RandD.developname`)}
                </span>

                <div className='Develop'>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.RandD.weightbold1`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.RandD.light1`)}
                    </span>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.RandD.weightbold2`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.RandD.light2`)}
                    </span>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.RandD.weightbold3`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.RandD.light3`)}
                    </span>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.RandD.weightbold4`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.RandD.light4`)}
                    </span>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.RandD.weightbold5`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.RandD.light5`)}
                    </span>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.RandD.weightbold6`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.RandD.light6`)}
                    </span>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.RandD.weightbold7`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.RandD.light7`)}
                    </span>
                </div>

                <div className='DevelopImg'>
                    <span className='TextDevelop'>
                        {t(`DetailTeam.RandD.textdevelop`)}
                    </span>
                    <img src={procedureImage} alt="DevelopRandD" />
                    <Link to="/detailDevelop">
                        <button className='SoDetailDevelop'>
                            {t(`DetailTeam.RandD.sodetaildevelop`)} &#62;
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default RandD; 