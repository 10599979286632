import React, { useState } from "react";
import {
    ECCELISLogo,
    CryptoBlendXLogo,
    CryptifyMELogo,
    CryptoBlockchainLogo
} from "../../asset/img/index";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";

const Solution = () => {
    const [hovered, setHovered] = useState(false);
    const { t } = useTranslation();

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    const ServiceItems = [
        {
            id: 1,
            Name: 'ECCELIS',
            explanation:
                <div>
                    {t(`Solution.ServiceItems.explanation1`)}
                </div>
            ,
            img: ECCELISLogo,
        },
        {
            id: 2,
            Name: 'CryptoBlendX',
            explanation:
                <div>
                    {t(`Solution.ServiceItems.explanation2`)}
                </div>,
            img: CryptoBlendXLogo,
        },
        {
            id: 3,
            Name: 'CryptifyME',
            explanation:
                <div>
                    {t(`Solution.ServiceItems.explanation3`)}
                </div>,
            img: CryptifyMELogo,
        },
        {
            id: 4,
            Name: 'Crypto Blockchain',
            explanation:
                <div>
                    {t(`Solution.ServiceItems.explanation4`)}
                </div>,
            img: CryptoBlockchainLogo,
        },
    ];

    return (
        <div>
            <div className="Sevicess">
                <div className="TextBox">
                    <span className="Explan">{t(`Solution.explan1`)}</span>
                    <span className="Name">{t(`Solution.name1`)}</span>
                </div>
                <div className="FlexBox">
                    {ServiceItems.map((item, index) => (
                        <div key={index} className="ServiceExplanBox">
                            <div
                                className="image-container"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img className="MainImg" src={item.img} alt={item.Name} />
                            </div>
                            <div className="ItemTextBox">
                                <span className="ItemName">{item.Name}</span>
                                <span className="ItemExplan">{item.explanation}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="MobileServicess">
                <span className="TextName">
                    {t(`Solution.name1`)}
                </span>
                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    showArrows={true}
                    showIndicators={false}
                    className="SlideSBox"
                    autoPlay
                    interval={5000}
                >
                    {ServiceItems.map(item => (
                        <div key={item.id} className="KeyFrame">
                            <img src={item.img} className="ImgFrame" alt={item.Name} />
                            <span className="ItemName">{item.Name}</span>
                            <span className="ItemExplan">{item.explanation}</span>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default Solution;

