import React from "react";
import {
  Koscom,
  Kdb,
  CNS,
  대전창조경제혁신센터,
  분당서울대학교병원,
  우리은행,
  프로카젠,
  하이파이브랩,
  전자인증,
  개인정보보호위원회,
  한국인터넷진흥원,
} from "../../asset/img/index";
import { useTranslation } from "react-i18next";

const PartnerOne = () => {
  const { t } = useTranslation();

  const partnerLogos = [
    Koscom,
    Kdb,
    CNS,
    대전창조경제혁신센터,
    분당서울대학교병원,
    우리은행,
    프로카젠,
    하이파이브랩,
    전자인증,
    개인정보보호위원회,
    한국인터넷진흥원,
  ];

  // 로고 간의 간격 없애기 위해 로고 배열을 섞습니다.
  // const shuffledLogos = [
  //   ...partnerLogos.slice(11),
  //   ...partnerLogos.slice(0, 11),
  // ];

  return (
    <div className="PartnerCompany">
      <div className="TitleWrapper">
        <span className="PartnerTitle">{t(`Footer.partnertitle`)}</span>
      </div>
      <div className="wrapper">
        <div className="SlideContainer">
          <ul className="SlideWrapper">
            <div className={"slide original"}>
              {partnerLogos.map((logo, index) => (
                <li key={logo} className="ImgWrapper">
                  <img
                    src={logo}
                    alt={`협력사 로고 ${index + 1}`}
                    className="item"
                  />
                </li>
              ))}
            </div>
            <div className={"slide clone"}>
              {partnerLogos.map((logo, index) => (
                <li key={logo} className="ImgWrapper">
                  <img
                    src={logo}
                    alt={`협력사 로고 ${index + 1}`}
                    className="item"
                  />
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PartnerOne;
