import api from "../api/auth";

const NewsRoomPostService = async (data, formData) => {
  console.log(data.writerEn);
  try {
    const response = await api.post("/save_news", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        category: data.category,
        categoryEn: data.categoryEn,
        date: data.date,
        writer: data.writer,
        writerEmail: data.writerEmail,
        writerEn: data.writerEn,
      },
    });

    return response.data;
  } catch (error) {
    console.error("서버 통신 오류:", error);
    throw error; // 에러를 상위로 다시 던져 처리
  }
};

const NewsRoomEditService = async (data, formData) => {
  try {
    const response = await api.put(`/update/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        category: data.category,
        categoryEn: data.categoryEn,
        date: data.date,
        writer: data.writer,
        writerEmail: data.writerEmail,
        writerEn: data.writerEn,
        id: data.id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const NewsDelete = ({ title }) => {
  return api.delete(`/delete_news/${title}`);
};

export { NewsRoomPostService, NewsRoomEditService, NewsDelete };
