import React, { createContext, useState, useContext } from "react";

// Context 생성
const LanguageContext = createContext();

// Provider 컴포넌트
export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState("ko"); // 초기값을 'en'으로 설정

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Context 값을 사용하기 위한 커스텀 훅
export const useLanguage = () => useContext(LanguageContext);
