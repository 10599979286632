import React, { useEffect, useState } from "react";
import NewsRoom from "../components/Community/NewsRoom";
import { useLocation } from "react-router-dom";
import {
  PartnerCompany
} from "../components/index";

interface CommunityPageProps {
  setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const CommunityPage: React.FC<CommunityPageProps> = ({ setCurrentSection }) => {
  const location = useLocation();

  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const elementId = location.hash.substring(1);
    scrollToElement(elementId);
  }, [location]);

  useEffect(() => {
    setCurrentSection("history");
    return () => {
    };
}, [setCurrentSection]);

  return (
    <div>
      <section id={"community1"}>
        <NewsRoom/>
      </section>
    </div>
  )
}
export default CommunityPage;