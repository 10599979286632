import { useTranslation } from "react-i18next";
import { Research, ResearchEN, RnDProcedure, RnDProcedureEN } from "../../asset/img";
import i18n from "../../locales/i18n";


const DetailDevelop = () => {
    const { t } = useTranslation();
    const isEN = i18n.language === 'en';

    return (
        <div className='FourchainsClass DetailDevelop'>
            <div className='Fourchains'>
                <span className='ShortName'>
                    {t(`DetailDevelop.shortname`)}
                </span>
                <span className='CenterName'>
                    {t(`DetailDevelop.centername`)}
                </span>
                <img src={isEN ? RnDProcedureEN : RnDProcedure} />
                <span className='DevelopName SubTitle'>
                    {t(`DetailDevelop.developname`)}
                </span>
                <img src={isEN ? ResearchEN : Research} />
            </div>
        </div>
    )
}

export default DetailDevelop;