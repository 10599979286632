import { useTranslation } from "react-i18next";
import {
    ServiceArrow,
} from "../../asset/img/index";
import { Link } from "react-router-dom";

const ServiceTab = () => {
    const { t } = useTranslation();

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const handleMenuClick = (section: string) => {
        scrollToTop();
    };


    return (
        <div className="ServiceTab">
            <div className="Tabls">
                <div className="SerSolTextBox">
                    <span className="Service">
                        {t(`ServiceTab.service`)}
                    </span>
                    <span className="SolSerText">
                        {t(`ServiceTab.solsertext`)}
                    </span>
                </div>
                <div className="SolSerBox">
                    <div className="btn-wrap">
                        <Link
                            className="button"
                            to="/detailSolution"
                            onClick={() => { handleMenuClick("") }}
                        >
                            <span className="button-overlay"></span>
                            <div className="SolutionBtn">
                                <div className="Left">
                                    <span className="SolutionTopText">
                                        {t(`ServiceTab.solutiontoptext`)}
                                    </span>
                                    <span className="SolutionBtmText">
                                        {t(`ServiceTab.solutionbtmtext`)}
                                    </span>
                                </div>
                                <div className="Right">
                                    <span className="SolutionRightText">
                                        {t(`ServiceTab.solutionrighttext`)}
                                    </span>
                                    <img className="TapNotImag" src={ServiceArrow} />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="btn-wrap">
                        <Link
                            className="button"
                            to="/detailService"
                            onClick={() => { handleMenuClick("") }}
                        >
                            <span className="button-overlay"></span>
                            <div className="ServiceTtn">
                                <div className="Left">
                                    <span className="ServiceTopText">
                                        {t(`ServiceTab.servicetoptext`)}
                                    </span>
                                    <span className="ServiceBtmText">
                                        {t(`ServiceTab.servicebtmtext`)}
                                    </span>
                                </div>
                                <div className="Right">
                                    <span className="ServiceRightText">
                                        {t(`ServiceTab.servicerighttext`)}
                                    </span>
                                    <img className="TapNotImag" src={ServiceArrow} />
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ServiceTab;