import axios from "axios";

const userToken = localStorage.getItem("user");

const api = axios.create({
  baseURL: "/api", // 백엔드 기본 url
  headers: {
    "Content-type": "application/json",
    Authorization: userToken ? `Bearer ${userToken}` : "",
  },
});

export default api;
