import { useEffect, useState } from "react";
import { NextPageArrow, PrevPageArrow } from "../../asset/img";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NewsRoomGetPostsService from "../../service/NewsRoomService";
import Search from "./Search";
import { animateScroll as scroll } from "react-scroll";
import Tab from "./Tab";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../locales/LangContext/LanguageContext";

export interface IPost {
  category: string;
  contents: string;
  contentsEn: string;
  createdAt: string;
  date: string;
  id: number;
  title: string;
  titleEn: string;
  updatedAt: string;
  writer: string;
  writerEn: string;
  writerEmail: string;
  imageUrl: string;
}

const NewsRoom = () => {
  const [active, setActive] = useState("전체");
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const { lang } = useLanguage();

  const postPerPage = 4;
  const pageLimit = 4;

  const [posts, setPosts] = useState<IPost[]>([]);
  const [mainPost, setMainPost] = useState<IPost>();
  const [totalPage, setTotalPage] = useState(0);
  const [totalPageArr, setTotalPageArr] = useState<number[][]>([]);
  const [pageArrNum, setPageArrNum] = useState(0);
  const [currentPageArr, setCurrentPageArr] = useState<number[]>([]);
  const [tabPosts, setTabPosts] = useState<IPost[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<IPost[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [noResult, setNoResult] = useState(false);

  const noResultArr = [1];

  const convertDate = (createdAt: string) => {
    const today = new Date(createdAt);
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    let todayDate = `${year}/${month}/${date}`;

    return todayDate;
  };

  const sliceArrByLimit = (totalPage: number, pageLimit: number) => {
    const totalPageArr = Array(totalPage)
      .fill("_")
      .map((_, i) => i + 1);
    return Array(Math.ceil(totalPage / pageLimit))
      .fill("_")
      .map(() => totalPageArr.splice(0, pageLimit));
  }; // 페이지가 네 개씩 나타나도록 페이지 배열 생성

  const getPageData = () => {
    const startIndex = (currentPage - 1) * postPerPage;
    const endIndex = startIndex + postPerPage;
    if (active === "전체") {
      return posts?.slice(startIndex, endIndex);
    } else {
      return tabPosts?.slice(startIndex, endIndex);
    }
  };

  const getFilteredPageData = () => {
    const startIndex = (currentPage - 1) * postPerPage;
    const endIndex = startIndex + postPerPage;
    return filteredPosts.slice(startIndex, endIndex);
  };

  const getPosts = async () => {
    try {
      const data = await NewsRoomGetPostsService();
      if (data?.data) {
        const sortedPosts = data?.data
          .sort(
            (a: IPost, b: IPost) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )
          .reverse();

        setMainPost(sortedPosts[0]);
        const allPosts = sortedPosts.slice(1);
        setPosts(allPosts);
        const totalPage = Math.ceil(allPosts.length / postPerPage);
        setTotalPage(totalPage);
        const slicedPageArr = sliceArrByLimit(totalPage, pageLimit);
        setTotalPageArr(slicedPageArr);
        setCurrentPageArr(slicedPageArr[pageArrNum]);
        setCurrentPage(slicedPageArr[pageArrNum][0]);
      }
    } catch (error) {
      console.error(error);
    }
  }; // 전체 post 데이터 불러오기

  useEffect(() => {
    getPosts();
  }, []);

  useEffect(() => {
    setSearchTerm("");
    setFilteredPosts([]);
    if (totalPage > 0) {
      if (active !== "전체") {
        const totalPage = Math.ceil(tabPosts?.length / postPerPage);
        setTotalPage(totalPage);
      } else {
        const totalPage = Math.ceil(posts?.length / postPerPage);
        setTotalPage(totalPage);
      }
    }
  }, [active]); // 선택된 탭에 따라 전체 페이지 수 변경

  useEffect(() => {
    if (totalPage > 0) {
      const slicedPageArr = sliceArrByLimit(totalPage, pageLimit);
      setTotalPageArr(slicedPageArr);
      if (filteredPosts.length > 0) {
        setCurrentPageArr(slicedPageArr[0]);
        setCurrentPage(slicedPageArr[0][0]);
      } else {
        setCurrentPageArr(slicedPageArr[pageArrNum]);
        setCurrentPage(slicedPageArr[pageArrNum][0]);
      }
    }
  }, [totalPage]); // 전체 페이지 수가 변경되면 전체 페이지 배열, 현재 페이지 배열 변경하고 현재 페이지 초기화

  useEffect(() => {
    if (totalPage > 0) {
      setCurrentPageArr(totalPageArr[pageArrNum]);
      setCurrentPage(totalPageArr[pageArrNum][0]);
    }
  }, [pageArrNum]); // 다음 페이지, 이전 페이지 버튼을 눌러서 이동했을 때 현재 페이지 배열, 현재 페이지 변경

  useEffect(() => {
    if (filteredPosts.length > 0) {
      setNoResult(false);
      setTotalPage(Math.ceil(filteredPosts?.length / postPerPage));
    } else {
      if (searchTerm.length > 0) {
        setNoResult(true);
        return;
      } else {
        setNoResult(false);
      }
      if (active !== "전체") {
        setTotalPage(Math.ceil(tabPosts?.length / postPerPage));
      } else {
        setTotalPage(Math.ceil(posts?.length / postPerPage));
      }
    }
  }, [filteredPosts]); // 검색했을 때 검색 결과에 따라 전체 페이지 수 변경

  const onClickPageBtn = (
    page: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault(); // 기본 동작 방지
    setCurrentPage(page);
    scrollToTop(); // 맨 위로 스크롤
  };

  const onClickPrevPage = () => {
    if (pageArrNum > 0) {
      setPageArrNum(pageArrNum - 1);
      setCurrentPage(currentPageArr[0]);
    }
  };

  const onClickNextPage = () => {
    if (totalPageArr.length > pageArrNum + 1) {
      setPageArrNum(pageArrNum + 1);
      setCurrentPage(currentPageArr[0]);
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 700,
      smooth: "easeInOutQuart",
    });
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleMenuClick = () => {
    scrollTop();
  };

  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    // 서버의 API 엔드포인트로 데이터 요청
    // fetch("https://localhost:3001/api/send_allnews")
    fetch("https://four-chains.com/api/send_allnews")
      .then((response) => response.json())
      .then((data) => setNewsData(data.data))
      .catch((error) => console.error("Error fetching news data:", error));
  }, []);

  return (
    <div className="NewsRoom">
      <div className="Page">
        <div className="NewContentContainer">
          <div className="NewContent">{t(`NewsRoom.newcontent`)}</div>
          <Link
            to={"/detailNews"}
            state={mainPost && mainPost}
            onClick={handleMenuClick}
          >
            <div className="ContentContainer">
              <div className="NewContentImgWrapper">
                <img
                  // src={`http://localhost:3001/${mainPost?.imageUrl}`}
                  src={`https://four-chains.com/${mainPost?.imageUrl}`}
                  alt={lang === "ko" ? mainPost?.title : mainPost?.titleEn}
                />
              </div>
              <div className="TextContainer">
                <div className="NewContentMainTitle">
                  {lang === "ko" ? mainPost?.title : mainPost?.titleEn}
                </div>
                <div className="Date">
                  {mainPost && convertDate(mainPost.createdAt)}
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="TabSearch">
          <Tab
            active={active}
            setActive={setActive}
            posts={posts}
            setTabPosts={setTabPosts}
            setPageArrNum={setPageArrNum}
            setCurrentPage={setCurrentPage}
          />
          <Search
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            active={active}
            setPageArrNum={setPageArrNum}
            setCurrentPage={setCurrentPage}
            tabPosts={tabPosts}
            posts={posts}
            setFilteredPosts={setFilteredPosts}
          />
        </div>
        <div className="Posts">
          {noResult ? (
            <div className="NoResults" style={{ color: "#252525" }}>
              검색 결과가 없습니다.
            </div>
          ) : (
            (filteredPosts.length > 0
              ? getFilteredPageData()
              : getPageData()
            )?.map((post: IPost, idx: number) => (
              <Link
                to={"/detailNews"}
                key={idx}
                state={post}
                onClick={handleMenuClick}
              >
                <div className="PostContainer">
                  <div className="PostImgWrapper">
                    <img
                      // src={`http://localhost:3001/${post?.imageUrl}`}
                      src={`https://four-chains.com/${post?.imageUrl}`}
                      alt={post?.title}
                    />
                  </div>
                  <div className="PostTextContainer">
                    <div className="PostTitle">
                      {lang === "ko" ? mainPost?.title : mainPost?.titleEn}
                    </div>
                    <div className="Date">{convertDate(post.createdAt)}</div>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>

        <Carousel
          className="MibilePstContain"
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          showIndicators={false}
          autoPlay
          interval={5000}
        >
          {(filteredPosts.length > 0
            ? filteredPosts
            : active === "전체"
            ? posts
            : tabPosts
          )?.map((post: IPost, idx: number) => (
            <Link
              to={"/detailNews"}
              key={idx}
              state={post}
              onClick={handleMenuClick}
            >
              <div className="PostContainer">
                <div className="PostImgWrapper">
                  <img
                    // src={`http://localhost:3001/${post?.imageUrl}`}
                    src={`https://four-chains.com/${post?.imageUrl}`}
                    alt={post.title}
                  />
                </div>
                <div className="PostTextContainer">
                  <div className="PostTitle">
                    {lang === "ko" ? post?.title : post?.titleEn}
                  </div>
                  <div className="Date">{convertDate(post.createdAt)}</div>
                </div>
              </div>
            </Link>
          ))}
        </Carousel>

        <div className="PageContainer">
          <img src={PrevPageArrow} onClick={onClickPrevPage} />
          {(noResult ? noResultArr : currentPageArr)?.map((page) => {
            return (
              <button
                key={page}
                className={page === currentPage ? "Active" : ""}
                onClick={(event) => onClickPageBtn(page, event)}
              >
                {page}
              </button>
            );
          })}
          <img src={NextPageArrow} onClick={onClickNextPage} />
        </div>
      </div>
    </div>
  );
};

export default NewsRoom;
