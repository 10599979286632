import React, { useState, useEffect } from "react";
import ContactService from "../service/ContactService";
import { useTranslation } from "react-i18next";

interface ContactProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const ContactUs: React.FC<ContactProps> = ({ setCurrentSection }) => {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [details, setDetails] = useState('');
    const [consentChecked, setConsentChecked] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [emailEmptyMessage, setEmailEmptyMessage] = useState('');
    const [titleEmptyMessage, setTitleEmptyMessage] = useState('');
    const [detailEmptyMessage, setDetailEmptyMessage] = useState('');
    const [formValid, setFormValid] = useState(true);
    const [buttonStyle, setButtonStyle] = useState({
        backgroundColor: 'gray',
        cursor: 'default'
    });

    useEffect(() => {
        setCurrentSection("WhiteHeader");
        return () => {
        };
    }, [setCurrentSection]);

    const handleSubmit = async () => {
        if (email && title && details && consentChecked) {
            try {
                await ContactService({ email, title, details });
                console.log("제출 성공!");
                setFormValid(true);
            } catch (error) {
                // POST 요청이 실패한 경우 에러 처리
                console.error("제출 실패:", error);
                setFormValid(false);
            }
        } else {
            setFormValid(false);
            setTitleEmptyMessage(!title ? '제목은 필수 입력 항목입니다.' : '');
            setDetailEmptyMessage(!details ? '문의 내용은 필수 입력 항목입니다.' : '');

            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!email) {
                setEmailEmptyMessage('이메일은 필수 입력 항목입니다.');
                setEmailErrorMessage('');
            } else if (!emailPattern.test(email)) {
                setEmailErrorMessage('이메일 형식이 올바르지 않습니다. 다시 확인해주세요.');
                setEmailEmptyMessage('');
            } else {
                setEmailErrorMessage('');
                setEmailEmptyMessage('');
            }
        }
    };

    useEffect(() => {
        if (email && title && details && consentChecked && !formValid) {
            setFormValid(true);
            setButtonStyle({
                backgroundColor: '#88A9A9',
                cursor: 'pointer'
            });
        } else if ((!email || !title || !details || !consentChecked) && formValid) {
            setFormValid(false);
            setButtonStyle({
                backgroundColor: '#959595',
                cursor: 'default'
            });
        }
    }, [email, title, details, consentChecked, formValid]);

    const textToType = t(`ContactUs.displaytext`);
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setDisplayedText('');
        setCurrentIndex(0);
    }, [i18n.language]);

    useEffect(() => {
        const typingInterval = setInterval(() => {
            if (currentIndex < textToType.length) {
                setDisplayedText((prevText) => prevText + textToType[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            } else {
                clearInterval(typingInterval);
            }
        }, 150); // 각 글자마다의 딜레이(ms)

        return () => clearInterval(typingInterval);
    }, [currentIndex, textToType]);

    // 초기에 한 번만 실행되도록 useEffect 추가
    useEffect(() => {
        setCurrentIndex(0);
    }, []);

    return (
        <div>
            <div className="ContactUs">
                <div className="ContactInput">
                    <div className="EmailContact">
                        <div className="EmailError">
                            <div className="Title">{t(`ContactUs.email`)}</div>
                            {(emailErrorMessage || emailEmptyMessage) && (
                                <div className="ErrorMessage">
                                    {emailErrorMessage || emailEmptyMessage}
                                </div>
                            )}
                        </div>
                        <input
                            type="text"
                            className="Email"
                            placeholder={t(`ContactUs.emailplace`)}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailEmptyMessage('');
                                setEmailErrorMessage('');
                            }}
                        />
                    </div>
                    <div className="ContactTitle">
                        <div className="EmailError">
                            <div className="Title">{t(`ContactUs.title`)}</div>
                            {titleEmptyMessage && <div className="ErrorMessage">{titleEmptyMessage}</div>}
                        </div>
                        <input
                            placeholder={t(`ContactUs.titleplace`)}
                            type="text"
                            className="InputTitle"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setTitleEmptyMessage('');
                            }} />
                    </div>
                    <div className="ContactDetail">
                        <div className="EmailError">
                            <div className="Title">contact</div>
                            {detailEmptyMessage && <div className="ErrorMessage">{detailEmptyMessage}</div>}
                        </div>
                        <textarea
                            placeholder={t(`ContactUs.contactplace`)}
                            className="InputDetail"
                            value={details}
                            onChange={(e) => {
                                setDetails(e.target.value);
                                setDetailEmptyMessage('');
                            }} />
                    </div>
                    <div className="InputBox">
                        <div className="BoxCheck">
                            <input
                                type="checkbox"
                                id="horns"
                                name="horns"
                                value={consentChecked.toString()}
                                onChange={(e) => {
                                    setConsentChecked(e.target.checked);
                                }}
                            />
                            <label htmlFor="horns">&nbsp;&nbsp;{t(`ContactUs.horns`)}</label>
                        </div>
                        <div
                            className="InputButton"
                            onClick={handleSubmit}
                            style={buttonStyle}
                        >
                            {t(`ContactUs.inputbutton`)}
                        </div>
                    </div>
                </div>
                <div className="ContactService">
                    <div className="ServiceTitle">
                        {displayedText}
                    </div>
                    <div className="Tel blur-animation">
                        {t(`ContactUs.tel`)}: 031-995-6409
                    </div>
                    <div className="Time blur-animation">
                        {t(`ContactUs.weekdays`)} / {t(`ContactUs.lunch`)}
                    </div>
                    <div className="Ask blur-animation">
                        {t(`ContactUs.IRPR`)} <br />
                        {t(`ContactUs.companyname`)}
                    </div>
                </div>
            </div>
            <div className="TabletContactUs">
                <div className="ServiceTitle">
                    {displayedText}
                </div>
                <div className="ContactInput">
                    <div className="EmailContact">
                        <div className="EmailError">
                            <div className="Title">{t(`ContactUs.email`)}</div>
                            {(emailErrorMessage || emailEmptyMessage) && (
                                <div className="ErrorMessage">
                                    {emailErrorMessage || emailEmptyMessage}
                                </div>
                            )}
                        </div>
                        <input
                            type="text"
                            className="Email"
                            placeholder={t(`ContactUs.emailplace`)}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailEmptyMessage('');
                                setEmailErrorMessage('');
                            }}
                        />
                    </div>
                    <div className="ContactTitle">
                        <div className="EmailError">
                            <div className="Title">{t(`ContactUs.title`)}</div>
                            {titleEmptyMessage && <div className="ErrorMessage">{titleEmptyMessage}</div>}
                        </div>
                        <input
                            placeholder={t(`ContactUs.titleplace`)}
                            type="text"
                            className="InputTitle"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setTitleEmptyMessage('');
                            }} />
                    </div>
                    <div className="ContactDetail">
                        <div className="EmailError">
                            <div className="Title">{t(`ContactUs.contact`)}</div>
                            {detailEmptyMessage && <div className="ErrorMessage">{detailEmptyMessage}</div>}
                        </div>
                        <textarea
                            placeholder={t(`ContactUs.contactplace`)}
                            className="InputDetail"
                            value={details}
                            onChange={(e) => {
                                setDetails(e.target.value);
                                setDetailEmptyMessage('');
                            }} />
                    </div>
                    <div className="InputBox_tablet">
                        <div className="BoxCheck">
                            <input
                                type="checkbox"
                                id="horns"
                                name="horns"
                                value={consentChecked.toString()}
                                onChange={(e) => {
                                    setConsentChecked(e.target.checked);
                                }}
                            />
                            <label htmlFor="horns">&nbsp;&nbsp;{t(`ContactUs.horns`)}</label>
                        </div>
                        <div
                            className="InputButton"
                            onClick={handleSubmit}
                            style={buttonStyle}
                        >
                            {t(`ContactUs.inputbutton`)}
                        </div>
                    </div>
                </div>
                <div className="ContactService">

                    <div className="Tel blur-animation">
                        {t(`ContactUs.tel`)}: 031-995-6409
                    </div>
                    <div className="Time blur-animation">
                        {t(`ContactUs.weekdays`)} / {t(`ContactUs.lunch`)}
                    </div>
                    <div className="Ask blur-animation">
                        {t(`ContactUs.IRPR`)}<br />
                        {t(`ContactUs.companyname`)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
