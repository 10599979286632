import React, { useEffect, useState } from "react";
import { ServiceProcedureImg, ServiceProcedureImgEN, ServiceProcedureImgmobile, ServiceProcedureImgmobileEN, SolutionProcedureEN } from "../../asset/img/index";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

const ServiceProcedure = () => {
    const { t } = useTranslation();
    //현재 언어정보
    const currentLanguage = i18n.language;
    const [isMobile, setIsMobile] = useState(false);

    // 화면 크기 변경 시 모바일 여부를 설정하는 함수
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 755);
    };

    // 화면 크기 변경 시마다 실행되도록 설정
    useEffect(() => {
        handleResize(); 
        window.addEventListener("resize", handleResize); 

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const procedureImage = currentLanguage === 'ko'
        ? (isMobile ? ServiceProcedureImgmobile : ServiceProcedureImg)
        : (isMobile ? ServiceProcedureImgmobileEN : ServiceProcedureImgEN);

    return (
        <div className="Procedure">
            <span className="Explanation">
                {t(`Servicess.explan2`)}
            </span>
            <span className="BigText">
                {t(`ServiceProcedure.1bigtext`)}
            </span>
            <img className="SolutionImg" src={procedureImage} />
        </div>
    )
}
export default ServiceProcedure;