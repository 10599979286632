import { useTranslation } from "react-i18next";
import { IPost } from "./NewsRoom";

// const TABITEM_LIST = ["전체", "뉴스", "소식"];

interface ITabProps {
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>,
  posts: IPost[],
  setTabPosts: React.Dispatch<React.SetStateAction<IPost[]>>,
  setPageArrNum: React.Dispatch<React.SetStateAction<number>>,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const Tab = ({ active, setActive, posts, setTabPosts, setPageArrNum, setCurrentPage }: ITabProps) => {
  const { t } = useTranslation();
  const TABITEM_LIST = [
    t(`NewsRoom.Tabitem.list1`),
    t(`NewsRoom.Tabitem.list2`),
    t(`NewsRoom.Tabitem.list3`)
  ];


  const onClickTab = (item: string) => {
    setActive(item);
    if (item !== "전체") {
      const filteredPosts = posts?.filter(
        (post: IPost) => post.category === item
      );
      setTabPosts(filteredPosts);
    }

    setPageArrNum(0);
    setCurrentPage(1);
  };

  return (
    <div className="Tab">
      {TABITEM_LIST.map((item) => (
        <div
          key={item}
          onClick={() => onClickTab(item)}
          className={active === item ? "TabItem Active" : "TabItem"}
        >
          {item}
        </div>
      ))}

      <a href="https://blog.naver.com/four-chains" target="_blank" className="Blog">{t(`NewsRoom.blog`)}</a>
    </div>
  )
}

export default Tab;