import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import {
    DepartRightArrow,
    DepartLeftArrow,
} from "../../asset/img/index";
import { Department } from "../index";
import { useTranslation } from "react-i18next";

const DepartmentCulture = () => {
    const { t } = useTranslation();

    const ArrowPrev = (onClickHandler: any, hasPrev: any, label: any) =>
        hasPrev && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="custom-arrow custom-arrow-prev"
            >
                <img src={DepartLeftArrow} alt="Prev" />
            </button>
        );

    const ArrowNext = (onClickHandler: any, hasNext: any, label: any) =>
        hasNext && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="custom-arrow custom-arrow-next"
            >
                <img src={DepartRightArrow} alt="Next" />
            </button>
        );

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const handleMenuClick = (section: string) => {
        scrollToTop();
    };

    const items = [
        {
            id: 1,
            MainName: t(`DepartmentCulture.items.mainname1`),
            SubName: t(`DepartmentCulture.items.subname1`),
            imgName: 'RnD',
        },
        {
            id: 2,
            MainName: t(`DepartmentCulture.items.mainname2`),
            SubName: t(`DepartmentCulture.items.subname2`),
            imgName: 'Development',
        },
        {
            id: 3,
            MainName: t(`DepartmentCulture.items.mainname3`),
            SubName: t(`DepartmentCulture.items.subname3`),
            imgName: 'Marketing',
        },
        {
            id: 4,
            MainName: t(`DepartmentCulture.items.mainname4`),
            SubName: t(`DepartmentCulture.items.subname4`),
            imgName: 'Management',
        },
    ];
    const filteredItemsOne = items.filter(item => item.id === 1);
    const filteredItemsTwo = items.filter(item => item.id === 2);
    const filteredItemsThree = items.filter(item => item.id === 3);
    const filteredItemsFour = items.filter(item => item.id === 4);
    return (
        <div className="Department">
            <div className="MainTitle">{t(`DepartmentCulture.maintitle`)}</div>

            <div className="Title">
                {t(`DepartmentCulture.departtitle`)}
            </div>

            <div className="MainSlide">
                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    renderArrowPrev={ArrowPrev}
                    renderArrowNext={ArrowNext}
                    className="SlideSBox"
                >
                    <div className="Box">
                        <Link to="/RandD" className="OneBox" onClick={() => { handleMenuClick("") }}>
                            <Department items={filteredItemsOne} />
                        </Link>
                        <Link to="/develop" className="OneBox" onClick={() => { handleMenuClick("") }}>
                            <Department items={filteredItemsTwo} />
                        </Link>
                    </div>
                    <div className="Box">
                        <Link to="/plan" className="OneBox" onClick={() => { handleMenuClick("") }}>
                            <Department items={filteredItemsThree} />
                        </Link>
                        <Link to="/manage" className="OneBox" onClick={() => { handleMenuClick("") }}>
                            <Department items={filteredItemsFour} />
                        </Link>
                    </div>
                </Carousel>
            </div>
        </div>
    );
};

export default DepartmentCulture;
