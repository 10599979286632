import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

const Ver2024 = () => {
    const { t } = useTranslation();
    const his = `History.events.2024.eventhis`;

    // 현재 언어 확인
    const isEN = i18n.language === 'en';

    return (
        <div className="History2019">
            <div className="Years2019">
                2024
            </div>
            <div className="BoldHis"
                style={{ fontSize: isEN ? '16px' : undefined }}>
                {t(`${his}.event1`)}<br />
                {t(`${his}.event2`)}<br />
                {t(`${his}.event3`)}<br />
                {t(`${his}.event4`)}<br />
                {t(`${his}.event5`)}<br />
                {t(`${his}.event6`)}<br />
                {t(`${his}.event7`)}<br />
                {t(`${his}.event8`)}
            </div>
            <div className="His2019">

            </div>
        </div>
    )
}

export default Ver2024;