import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

const Ver2022 = () => {
    const { t } = useTranslation();
    const his = `History.events.2022`;

    // 현재 언어 확인
    const isEN = i18n.language === 'en';

    return (
        <div className="History2019">
            <div className="Years2019">
                2022
            </div>
            <div className="BoldHis"
                style={{ fontSize: isEN ? '16px' : undefined }}>
                {t(`${his}.eventbold.event1`)}
            </div>
            <div className="His2019"
                style={{ fontSize: isEN ? '14px' : undefined }}>
                {t(`${his}.eventhis.event1`)}
            </div>
        </div>
    )
}

export default Ver2022;