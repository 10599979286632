import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { useLanguage } from "../../locales/LangContext/LanguageContext";

interface DetailNewsProps {
  setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const DetailNews: React.FC<DetailNewsProps> = ({ setCurrentSection }) => {
  const { state: data } = useLocation();
  const { lang } = useLanguage();

  const today = new Date(data?.createdAt);
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let date = today.getDate();

  let todayDate = `${year}/${month}/${date}`;

  useEffect(() => {
    setCurrentSection("history");
    return () => {};
  }, [setCurrentSection]);

  return (
    <div className="DetailNews">
      <div className="NewsBox">
        <span className="NewsTitle">
          {lang === "ko" ? data?.title : data?.titleEn}
        </span>
        <span className="MobileNewsName">
          {lang === "ko" ? data?.writer : data?.writer} | {data?.writerEmail}
        </span>
        <span className="NewsDate">
          {lang === "ko" ? data?.category : data?.categoryEn} |{" "}
          {data && todayDate}
        </span>
        <img
          className="DetailImage"
          // src={`http://localhost:3001/${data?.imageUrl}`}
          src={`https://four-chains.com/${data?.imageUrl}`}
          alt={lang === "ko" ? data?.title : data?.titleEn}
        />
        <div className="ql-snow">
          <div
            className="ql-editor ContentsWrapper"
            dangerouslySetInnerHTML={{
              __html: lang === "ko" ? data?.contents : data?.contentsEn,
            }}
          ></div>
        </div>
        <div className="Btm">
          <span className="NewsName">
            {lang === "ko" ? data?.writer : data?.writerEn} |{" "}
            {data?.writerEmail}
          </span>

          <div className="NewsAsk">
            <Link to="/Contact">
              <span>IR | PR 문의하기</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailNews;
