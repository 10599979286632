import React, { useEffect } from "react";
import { ModalDragon } from "../../asset/img/index";
interface NewsCheckModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  form: {
    category: string;
    creator: string;
    creatorEmail: string;
    title: string;
  };
  valueKR: string;
  valueEN: string;
  date: string;
}

const NewsCheckModal: React.FC<NewsCheckModalProps> = ({
  form,
  valueKR,
  valueEN,
  date,
  onConfirm,
  onCancel,
}) => {
  const body = document.getElementsByTagName("body")[0];
  const scrollDisable = () => {
    window.scrollTo(0, 150);
    body.classList.add("scrollHidden");
  };
  const scrollAble = () => {
    body.classList.remove("scrollHidden");
  };

  useEffect(() => {
    scrollDisable();
    return () => scrollAble();
  }, []);

  return (
    <div className="NewsCheckModal">
      <div className="NewsCheck">
        <span className="Error">주의</span>
        <span className="OneMore">정말 홈페이지에 글을 게시하겠습니까?</span>
        <span className="Text">수정할 곳을 다시 한 번 확인해 주세요!</span>
        <div className="CheckBtn">
          <button className="Ok" onClick={onConfirm}>
            확인
          </button>
          <button className="No" onClick={onCancel}>
            취소
          </button>
        </div>
        <img className="ModalDragon" src={ModalDragon} alt="Dragon" />
      </div>
    </div>
  );
};

export default NewsCheckModal;
