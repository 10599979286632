import React, { useEffect } from 'react';
import { PlanBack, DevelopPlan, DevelopPlanEN } from "../../asset/img/index";
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';


interface DevelopProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Develop: React.FC<DevelopProps> = ({ setCurrentSection }) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const procedureImage = currentLanguage === "ko" ? DevelopPlan : DevelopPlanEN; 

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'>
                <span className='ShortName'>
                    {t(`DetailTeam.shortname`)}
                </span>
                <span className='CenterName'>
                    {t(`DetailTeam.Plan.centername`)}
                </span>
                <img src={PlanBack} alt="PlanBack" />
                <span className='DevelopName'>
                    {t(`DetailTeam.Plan.developname`)}
                </span>


                <div className='Develop'>
                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Plan.weightbold1`)}
                    </span>
                    <span className='Bold'>
                        {t(`DetailTeam.Plan.bold1`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Plan.light1`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Plan.weightbold2`)}
                    </span>
                    <span className='Bold'>
                        {t(`DetailTeam.Plan.bold2`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Plan.light2`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Plan.weightbold3`)}
                    </span>
                    <span className='Bold'>
                        {t(`DetailTeam.Plan.bold3`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Plan.light3`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Plan.weightbold4`)}
                    </span>
                    <span className='Bold'>
                        {t(`DetailTeam.Plan.bold4`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Plan.light4`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Plan.weightbold5`)}
                    </span>
                    <span className='Bold'>
                        {t(`DetailTeam.Plan.bold5`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Plan.light5`)}
                    </span>

                    <span className='Bold weightBold'>
                        {t(`DetailTeam.Plan.weightbold6`)}
                    </span>
                    <span className='Bold'>
                        {t(`DetailTeam.Plan.bold6`)}
                    </span>
                    <span className='Light'>
                        {t(`DetailTeam.Plan.light6`)}
                    </span>

                    <div className='DevelopImg'>
                        <img src={procedureImage} alt="DevelopPlan" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Develop; 