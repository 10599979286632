import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  TeamSlideOne,
  TeamSlideThree,
  PrevSlide,
  NextSlide,
  TeamSlideFour,
  TeamSlideTwo,
} from "../../asset/img/index";
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';

interface SlideProps {
  isCurtureSlidePage: boolean;
}

const SlideComponent: React.FC<SlideProps> = ({ isCurtureSlidePage }) => {
  const { t } = useTranslation();
  const isEN = i18n.language === 'en';
  const [tablet, setTablet] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    setTablet(width > 320 && width <= 756);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const heightt = {
    height: isEN ? 'auto' : undefined
  }

  const items = [
    `#${t(`TeamSlide.box1`)}`,
    `#${t(`TeamSlide.box2`)}`,
    `#${t(`TeamSlide.box3`)}`,
    `#${t(`TeamSlide.box4`)}`
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const blurAnimation = isCurtureSlidePage
    ? 'blurUp 0.7s ease-in-out'
    : 'none';

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % items.length);
    }, 7000);

    return () => clearInterval(intervalId);
  }, [currentSlide]);

  return (
    <div className='TeamSlide'
    //   style={{
    //     animation: blurAnimation,
    //     opacity: isCurtureSlidePage ? 1 : 0,
    // }}
    >
      <div className='Start'>
        {t(`TeamSlide.start`)}
      </div>
      <div className='HashTagBox'>
        {items.map((item, index) => (
          <div className='HashTag' key={index} style={heightt}>
            {item}
          </div>
        ))}
      </div>
      <Carousel
        className='SlideCarousel'
        showThumbs={false}
        showStatus={false}
        selectedItem={currentSlide}
        showIndicators={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button onClick={onClickHandler} title={label} className="arrow-prev">
              <img src={PrevSlide} />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button onClick={onClickHandler} title={label} className="arrow-next">
              <img src={NextSlide} />
            </button>
          )
        }
      >
        <div className='Slide'>
          <img src={TeamSlideOne} alt="Slide a" />
          <div className='SlideTextBox'>
            <div className='Box'>
              {t(`TeamSlide.box1`)}
            </div>
            <span>
              {t(`TeamSlide.box1content`)}
            </span>
          </div>
        </div>
        <div className='Slide'>
          <img src={TeamSlideTwo} alt="Slide b" />
          <div className='SlideTextBox'>
            <div className='Box'>
              {t(`TeamSlide.box2`)}
            </div>
            <span>
              {t(`TeamSlide.box2content`)}
            </span>
          </div>
        </div>
        <div className='Slide'>
          <img src={TeamSlideThree} alt="Slide c" />
          <div className='SlideTextBox'>
            <div className='Box'>
              {t(`TeamSlide.box3`)}
            </div>
            <span>
              {t(`TeamSlide.box3content`)}
            </span>
          </div>
        </div>
        <div className='Slide'>
          <img src={TeamSlideFour} alt="Slide b" />
          <div className='SlideTextBox'>
            <div className='Box'>
              {t(`TeamSlide.box4`)}
            </div>
            <span>
              {t(`TeamSlide.box4content`)}
            </span>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default SlideComponent;
