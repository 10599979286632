import React, { useState } from "react";
import {
    DetailServiceProbe,
    DetailServiceBI,
    DetailServiceModulus,
    DetailServiceStrong,
    DetailServiceEHT,
    DetailServicePixeLock,
    DetailServiceCrypto,
    DetailServiceStrongBox,
} from "../../asset/img/index";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";

const Servicess = () => {
    const [hovered, setHovered] = useState(false);
    const { t } = useTranslation();

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    const ServiceItems = [
        {
            id: 1,
            Name: 'StrongBox',
            explanation:
                <div>
                    {t(`Servicess.ServiceItems.1explanation`)}
                </div>,
            img: DetailServiceStrong,
            link: "http://crypto-blockchain.org/"
        },
        {
            id: 2,
            Name: 'Modulus',
            explanation:
                <div>
                    {t(`Servicess.ServiceItems.2explanation`)}
                </div>,
            img: DetailServiceModulus,
            link: "https://fcts-mod.com/"
        },
        {
            id: 3,
            Name: 'BI-Mining',
            explanation:
                <div>
                    {t(`Servicess.ServiceItems.3explanation`)}
                </div>,
            img: DetailServiceBI,
            link: "https://bi-mining.org/"
        },
        {
            id: 4,
            Name: 'Probe',
            explanation:
                <div>
                    {t(`Servicess.ServiceItems.4explanation`)}
                </div>,
            img: DetailServiceProbe,
            link: "https://probe.kr/about"
        },
        {
            id: 5,
            Name: 'EHT',
            explanation:
                <div>
                    {t(`Servicess.ServiceItems.5explanation`)}
                </div>,
            img: DetailServiceEHT,
            link: "https://eht.im/"
        },
        {
            id: 6,
            Name: 'PixeLock',
            explanation:
                <div>
                    {t(`Servicess.ServiceItems.6explanation`)}
                </div>,
            img: DetailServicePixeLock,
            link: "https://pixelock.im/"
        },
        {
            id: 7,
            Name: 'Crypto Blockchain',
            explanation:
                <div>
                    {t(`Servicess.ServiceItems.7explanation`)}
                </div>,
            img: DetailServiceCrypto,
            link: "https://cryptoblockchain.im/"
        },
        {
            id: 8,
            Name: 'StrongBox',
            explanation:
                <div>
                    {t(`Servicess.ServiceItems.8explanation`)}
                </div>,
            img: DetailServiceStrongBox,
            link: "https://strongbox.im/"
        },
    ];

    return (
        <div>
            <div className="Sevicess">
                <div className="TextBox">
                    <span className="Explan">{t(`Servicess.explan2`)}</span>
                    <span className="Name">{t(`Servicess.name2`)}</span>
                </div>
                <div className="FlexBox">
                    {ServiceItems.map((item, index) => (
                        <div key={index} className="ServiceExplanBox">
                            <Link to={item.link}
                                target="_blank"
                                className="image-container"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img className="MainImg" src={item.img} alt={item.Name} />
                                {hovered && (
                                    <div className="overlay">
                                        <span className="overlay-text">{t(`Servicess.go`)}</span>
                                    </div>
                                )}
                            </Link>
                            <div className="ItemTextBox">
                                <span className="ItemName">{item.Name}</span>
                                <span className="ItemExplan">{item.explanation}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="MobileServicess">
                <span className="TextName">
                    {t(`Servicess.name2`)}
                </span>
                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    showArrows={true}
                    showIndicators={false}
                    className="SlideSBox"
                    autoPlay
                    interval={5000}
                >
                    {ServiceItems.map(item => (
                        <div key={item.id} className="KeyFrame">
                            <img src={item.img} className="ImgFrame" alt={item.Name} />
                            <span className="ItemName">{item.Name}</span>
                            <span className="ItemExplan">{item.explanation}</span>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default Servicess;
