/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../style/components/Privacy.scss";

const Privacy = () => {
  return (
    <>
      <div id="Back_color">
        <header>
          <h1>FOUR-CHAINS 개인정보 처리 방침</h1>
        </header>
        <div className="images">
          <img src={require("../asset/Privacy/Privacy1.png")} alt="Privacy1" />
          <img src={require("../asset/Privacy/Privacy2.png")} alt="Privacy2" />
          <img src={require("../asset/Privacy/Privacy3.png")} alt="Privacy3" />
          <img src={require("../asset/Privacy/Privacy4.png")} alt="Privacy4" />
          <img src={require("../asset/Privacy/Privacy5.png")} alt="Privacy5" />
          <img src={require("../asset/Privacy/Privacy6.png")} alt="Privacy6" />
        </div>
      </div>
    </>
  );
};

export default Privacy;
